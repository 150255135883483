import { useContext, useState } from "react";
import { AppContext } from "../../context/app.context";
import { ScheduleContext } from "../../context/schedule.context";
import {
  FILTERS_CLASSIFICATION_ARRAY,
  FILTERS_CREW_ARRAY,
  FILTERS_CREW_SURFACE_ARRAY,
  FILTERS_SURFACE_ARRAY,
} from "../../utility/keys";
import Checkbox from "../../components/Checkbox";
import Input from "../../components/Input";
import search from "./../../assets/icons/search.svg";

const JobsHeader = () => {
  const [hideFilter, setHideFilter] = useState(true);

  const {
    locationFilter,
    locationArray,
    setLocationFilter,
    currentFilters,
    onCrewFiltersChanged,
    onCrewSurfaceFiltersChanged,
    onClassificationFiltersChanged,
    onSurfaceFiltersChanged,
  } = useContext(AppContext);

  const {
    schedulePreparing,
    setScheduleNeedsModel,
    projectListQuery,
    setProjectListQuery,
  } = useContext(ScheduleContext);

  // - - - - - - - - - -

  const onLocationChange = (event) => {
    // user is changing select location filter
    const newLocation = event.target.value;
    console.log("App onLocationChange: ", newLocation);
    setLocationFilter(newLocation);
    setScheduleNeedsModel(true);
  };

  // - - - - - - - - - -

  const onSearch = () => {
    console.log("JobsHeader onSearch:", projectListQuery);
  };

  return (
    <>
      <section className="flex gap-4 text-sm font-medium">
        <span className="text-lg leading-tight font-bold text-skin-primary">
          All Jobs
        </span>
        <span className="w-10"></span>

        <label htmlFor="location" className="self-center">
          Location
        </label>
        <select
          id="location"
          className="bg-yellow-30 text-black text-sm font-medium hover:opacity-75 cursor-pointer"
          onChange={onLocationChange}
          disabled={schedulePreparing}
          value={locationFilter}
        >
          {locationArray.map((location, index) => (
            <option value={location} key={index}>
              {location}
            </option>
          ))}
        </select>

        <span className="flex-1"></span>
        <div className="h-6">
          <Input
            placeholder="Quick Search"
            value={projectListQuery}
            onChange={(e) => setProjectListQuery(e.target.value)}
            onEnter={() => onSearch()}
            className="bg-yellow-30 text-black"
          >
            <Input.Suffix>
              <button className="w-6" onClick={() => onSearch()}>
                <img src={search} alt="search" />
              </button>
            </Input.Suffix>
          </Input>
        </div>

        <button onClick={() => setHideFilter(!hideFilter)} className="ml-2">
          {hideFilter ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-skin-base"
            >
              <g clipPath="url(#clip0_1_539)">
                <path d="M6.99999 6H17L11.99 12.3L6.99999 6ZM4.24999 5.61C6.26999 8.2 9.99999 13 9.99999 13V19C9.99999 19.55 10.45 20 11 20H13C13.55 20 14 19.55 14 19V13C14 13 17.72 8.2 19.74 5.61C20.25 4.95 19.78 4 18.95 4H5.03999C4.20999 4 3.73999 4.95 4.24999 5.61Z" />
              </g>
              <defs>
                <clipPath id="clip0_1_539">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-skin-base"
            >
              <mask
                id="mask0_21_2701"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" />
              </mask>
              <g mask="url(#mask0_21_2701)">
                <path d="M14.7999 11.975L13.3749 10.55L16.9499 6H8.8249L6.8249 4H18.9999C19.4166 4 19.7166 4.18334 19.8999 4.55C20.0832 4.91667 20.0499 5.26667 19.7999 5.6L14.7999 11.975ZM19.7749 22.6L13.9999 16.825V19C13.9999 19.2833 13.9041 19.5208 13.7124 19.7125C13.5207 19.9042 13.2832 20 12.9999 20H10.9999C10.7166 20 10.4791 19.9042 10.2874 19.7125C10.0957 19.5208 9.9999 19.2833 9.9999 19V12.825L1.3999 4.225L2.7999 2.8L21.1999 21.2L19.7749 22.6Z" />
              </g>
            </svg>
          )}
        </button>
      </section>
      {hideFilter ? (
        ""
      ) : (
        <section className="mt-8">
          <div className="flex">
            <span className="w-14 text-sm font-medium">Crews</span>
            {FILTERS_CREW_SURFACE_ARRAY.map(({ name }, index) => {
              return (
                <div className="flex-1" key={index}>
                  <Checkbox
                    label={name}
                    disabled={schedulePreparing}
                    value={currentFilters.crewSurfaceFilters[index]}
                    onChange={() => onCrewSurfaceFiltersChanged(index)}
                  />
                </div>
              );
            })}
            {FILTERS_CREW_ARRAY.map(({ name }, index) => {
              return (
                <div className="flex-1" key={index}>
                  <Checkbox
                    label={name}
                    disabled={schedulePreparing}
                    value={currentFilters.crewFilters[index]}
                    onChange={() => onCrewFiltersChanged(index)}
                  />
                </div>
              );
            })}
            <span className="flex-1"></span>
          </div>
          <div className="flex">
            <span className="w-14 text-sm font-medium">Jobs</span>
            {FILTERS_CLASSIFICATION_ARRAY.map(({ name }, index) => {
              return (
                <div className="flex-1 overflow-hidden" key={index}>
                  <Checkbox
                    label={name}
                    disabled={schedulePreparing}
                    value={currentFilters.classificationFilters[index]}
                    onChange={() => onClassificationFiltersChanged(index)}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex">
            <span className="w-14 text-sm font-medium">Surface</span>
            {FILTERS_SURFACE_ARRAY.map(({ name }, index) => {
              return (
                <div className="flex-1 overflow-hidden" key={index}>
                  <Checkbox
                    label={name}
                    disabled={schedulePreparing}
                    value={currentFilters.surfaceFilters[index]}
                    onChange={() => onSurfaceFiltersChanged(index)}
                  />
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default JobsHeader;
