import { createContext, useState, useEffect } from "react";
import kiLogo from "./../assets/Logo_KI.png";
import scgLogo from "./../assets/Logo_SCG.png";
import {
  MILLISECONDS_PER_DAY,
  FILTERS_SURFACE_ARRAY,
  FILTERS_CLASSIFICATION_ARRAY,
  FILTERS_CREW_ARRAY,
  FILTERS_CREW_SURFACE_ARRAY,
} from "../utility/keys";

// - - - - - - - - - - - - - - - - - - - -

export const defaultUImode = "dark";
export const defaultCompanyList = ["KI", "SCG"];
export const defaultCompanyCode = "KI";
export const defaultLocationFilter = "CHS";
export const defaultRoleList = ["coord_all"];
export const defaultJobTitleConfig = [
  { name: "Flags", field: "flags", selected: true },
  { name: "Customer Last Name", field: "lastName", selected: true },
  { name: "Qty", field: "qty", selected: true },
  { name: "Store #", field: "storeNo", selected: true },
  { name: "Arrival", field: "encodedArrival", selected: true },
  { name: "Classification", field: "classification", selected: true },
  { name: "Status", field: "statusCode", selected: false },
  { name: "Invoice #", field: "invoiceNo", selected: false },
  { name: "Project #", field: "projectNo", selected: false },
];
export const defaultFilters = {
  surfaceFilters: [true, true, true, true, true, true, true],
  classificationFilters: [true, true, true, true, true, true, true],
  crewFilters: [true, true, true, true],
  crewSurfaceFilters: [true, true],
};
export const defaultScreenAccessList = ["optimizer", "scheduler", "jobList"];
export const companies = [
  { code: "KI", name: "Keller Interiors", logo: kiLogo },
  { code: "SCG", name: "SCG", logo: scgLogo },
];

// - - - - - - - - - - - - - - - - - - - -

export const AppContext = createContext();

// - - - - - - - - - -

export const AppContextProvider = ({ children }) => {
  // date calculations
  const dateToday = new Date();
  const dateTodayTime = dateToday.getTime();
  const dateTodayDayOfWeek = dateToday.getDay(); // sun=0, mon=1, tue=2
  const dateTodayDifferenceToMonday =
    (1 - dateTodayDayOfWeek) * MILLISECONDS_PER_DAY; // sun=1, mon=0, tue=-1
  const dateMonday = new Date(dateTodayTime + dateTodayDifferenceToMonday);

  const [companyCode, setCompanyCode] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [screenAccess, setScreenAccess] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [projectDetail, setProjectDetail] = useState([]);
  const [candidateDetail, setCandidateDetail] = useState({});
  const [weekDatesArray, setWeekDatesArray] = useState([]);
  const [mondayThisWeek, setMondayThisWeek] = useState(dateMonday);
  const [latestChangeTS, setLatestChangeTS] = useState(dateToday);
  const [countsToggle, setCountsToggle] = useState(false);
  const [lockAccess_unassigned, setLockAccess_unassigned] = useState(false);
  const [lockAccess_assigned, setLockAccess_assigned] = useState(false);
  const [openJobsDetailModal, setOpenJobsDetailModal] = useState(false);
  const [openCrewDetailModal, setOpenCrewDetailModal] = useState(false);
  const [openJobSettingsModal, setOpenJobSettingsModal] = useState(false);
  const [openSuppressModal, setOpenSuppressModal] = useState(false);

  const surfaceFilters = new Array(FILTERS_SURFACE_ARRAY.length).fill(true);
  const classificationFilters = new Array(
    FILTERS_CLASSIFICATION_ARRAY.length
  ).fill(true);
  const crewFilters = new Array(FILTERS_CREW_ARRAY.length).fill(true);
  const crewSurfaceFilters = new Array(FILTERS_CREW_SURFACE_ARRAY.length).fill(
    true
  );
  const [currentFilters, setCurrentFilters] = useState({
    surfaceFilters: surfaceFilters,
    classificationFilters: classificationFilters,
    crewFilters: crewFilters,
    crewSurfaceFilters: crewSurfaceFilters,
  });
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) ?? true
  );
  const [whichList, setWhichList] = useState("schedule");
  const [largeView, setLargeView] = useState(
    JSON.parse(localStorage.getItem("largeView")) ?? true
  );
  const [jobTitleConfig, setJobTitleConfig] = useState("");
  // JSON.parse(localStorage.getItem("jobTitleConfig")) ?? JOB_INFO_CHECKBOXES

  const environs = {
    nodeEnv: process.env.NODE_ENV,
    appName: process.env.REACT_APP_NAME,
    appEnv: process.env.REACT_APP_ENV,
    mongoDb: process.env.REACT_APP_MONGODB_DATABASE,
    hostName: window.location.hostname,
  };
  console.log(
    "AppContext  hostname: ",
    environs.hostName,
    ", NODE_ENV: ",
    environs.nodeEnv,
    ", REACT_APP_NAME: ",
    environs.appName,
    ", REACT_APP_ENV: ",
    environs.appEnv,
    ", MONGODB_DATABASE: ",
    environs.mongoDb
  );
  const [appEnvirons, setAppEnvirons] = useState(environs);
  const [defaultNewUserRecord, setDefaultNewUserRecord] = useState({
    companyList: defaultCompanyList,
    defaultCompany: defaultCompanyCode,
    defaultLocation: defaultLocationFilter,
    defaultUImode: defaultUImode,
    defaultCountsToggle: false,
    defaultFilters: defaultFilters,
    locationList: [],
    roleList: defaultRoleList,
    screenAccessList: defaultScreenAccessList,
    jobTitleConfig: defaultJobTitleConfig,
  });

  // - - - - - - - - - -

  const dataFormatting_dateFields = (scheduledDate) => {
    // transform incoming MongoDB document data into required format
    // remove leading zeros from scheduledDate
    return new Date(scheduledDate).toLocaleDateString("en-us");
  };

  // - - - - - - - - - -

  const dataFormatting_payrollFields = (payrollNo) => {
    // transform incoming MongoDB document data into required format
    const payrollString = payrollNo.toString();
    const payrollLength = payrollString.length;
    const decimalPosition = payrollString.indexOf(".");

    if (decimalPosition === payrollLength - 2) {
      // suffix has a single digit ie 3264.4
      payrollNo = payrollNo.toString() + "0";
    }

    // pad leading zeros to front of payrollNos
    return payrollNo.toString().padStart(7, "0");
  };

  // - - - - - - - - - -

  const dataFormatting_encodedSmallTitle = (job) => {
    // transform incoming MongoDB document data into U.I. title
    // KellerInteriors::Kanban = "slots":["Qty","Surface","StoreNo","Arrival","Name_Last"]
    const expressShip = job.expressShip === 1 ? "📦" : "";
    const furnitureFlag = job.furniture ? "🪑" : "";
    const prescheduledFlag = job.prescheduled ? "📅" : "";
    const rushFlag = job.rush ? "⚡️" : "";
    const expedite = job.jobAttributes?.expedite ? "🏃🏽‍♂️" : "";
    const distant = job.jobAttributes?.distant ? "✈️" : "";
    var encodedSurface = "";
    switch (job.surface) {
      case "Carpet":
        encodedSurface = "C";
        break;
      case "Vinyl":
        encodedSurface = "V";
        break;
      case "Plank":
        encodedSurface = "P";
        break;
      case "Ceramic":
        encodedSurface = "T";
        break;
      case "Wood":
        encodedSurface = "W";
        break;
      case "Backsplash":
        encodedSurface = "B";
        break;
      case "Pad":
        break;
      default:
        encodedSurface = "U";
    }

    var encodedArrival = "";
    job.arrival === ""
      ? (encodedArrival = "?-?")
      : (encodedArrival = job.arrival);
    encodedArrival = encodedArrival.replaceAll(" ", "");
    encodedArrival = encodedArrival.replaceAll(":00AM", "");
    encodedArrival = encodedArrival.replaceAll(":00PM", "");
    encodedArrival = encodedArrival.replaceAll(":30AM", ":30");
    encodedArrival = encodedArrival.replaceAll(":30PM", ":30");
    encodedArrival = encodedArrival.replaceAll("a.m.", "");
    encodedArrival = encodedArrival.replaceAll("p.m.", "");
    encodedArrival = encodedArrival.replaceAll("Noon", "12");
    encodedArrival = encodedArrival.replaceAll("Morning", "");
    encodedArrival = encodedArrival.replaceAll("Afternoon", "");
    encodedArrival = encodedArrival.replaceAll("Evening", "");

    var encodedSmallTitle = "";
    encodedSmallTitle += rushFlag;
    encodedSmallTitle += expressShip;
    encodedSmallTitle += expedite;
    encodedSmallTitle += distant;
    encodedSmallTitle += job.qty;
    encodedSmallTitle += encodedSurface;
    encodedSmallTitle += job.storeNo;
    encodedSmallTitle += furnitureFlag;
    encodedSmallTitle += prescheduledFlag;
    encodedSmallTitle += encodedArrival;
    encodedSmallTitle += job.lastName;

    return encodedSmallTitle;
  };

  // - - - - - - - - - -

  const dataFormatting_encodedLargeTitle = (job) => {
    // transform incoming MongoDB document data into U.I. title
    // KellerInteriors::Kanban = "slots":["Qty","Surface","StoreNo","Arrival","Name_Last"]
    const expressShip = job.expressShip === 1 ? "📦" : "";
    const furnitureFlag = job.furniture ? "🪑" : "";
    const prescheduledFlag = job.prescheduled ? "📅" : "";
    const rushFlag = job.rush ? "⚡️" : "";
    const expedite = job.jobAttributes?.expedite ? "🏃🏽‍♂️" : "";
    const distant = job.jobAttributes?.distant ? "✈️" : "";
    var encodedSurface = "";
    switch (job.surface) {
      case "Carpet":
        encodedSurface = "Cpt";
        break;
      case "Vinyl":
        encodedSurface = "Vyl";
        break;
      case "Plank":
        encodedSurface = "Plk";
        break;
      case "Ceramic":
        encodedSurface = "Til";
        break;
      case "Wood":
        encodedSurface = "Wd";
        break;
      case "Backsplash":
        encodedSurface = "Bksp";
        break;
      case "Pad":
        break;
      default:
        encodedSurface = "UNK";
    }

    var encodedArrival = "";
    job.arrival === ""
      ? (encodedArrival = "?-?")
      : (encodedArrival = job.arrival);
    encodedArrival = encodedArrival.replaceAll(" ", "");
    encodedArrival = encodedArrival.replaceAll(":00AM", "");
    encodedArrival = encodedArrival.replaceAll(":00PM", "");
    encodedArrival = encodedArrival.replaceAll(":30AM", ":30");
    encodedArrival = encodedArrival.replaceAll(":30PM", ":30");
    encodedArrival = encodedArrival.replaceAll("a.m.", "");
    encodedArrival = encodedArrival.replaceAll("p.m.", "");
    encodedArrival = encodedArrival.replaceAll("Noon", "12");
    encodedArrival = encodedArrival.replaceAll("Morning", "");
    encodedArrival = encodedArrival.replaceAll("Afternoon", "");
    encodedArrival = encodedArrival.replaceAll("Evening", "");

    var encodedLargeTitle = "";
    encodedLargeTitle += rushFlag;
    encodedLargeTitle += rushFlag ? "•" : "";
    encodedLargeTitle += expressShip;
    encodedLargeTitle += expressShip ? "•" : "";
    encodedLargeTitle += expedite;
    encodedLargeTitle += expedite ? "•" : "";
    encodedLargeTitle += distant;
    encodedLargeTitle += distant ? "•" : "";
    encodedLargeTitle += job.qty;
    encodedLargeTitle += "•";
    encodedLargeTitle += encodedSurface;
    encodedLargeTitle += "•";
    encodedLargeTitle += job.storeNo;
    encodedLargeTitle += "•";
    encodedLargeTitle += furnitureFlag;
    encodedLargeTitle += furnitureFlag ? "•" : "";
    encodedLargeTitle += prescheduledFlag;
    encodedLargeTitle += prescheduledFlag ? "•" : "";
    encodedLargeTitle += encodedArrival;
    encodedLargeTitle += "•";
    encodedLargeTitle += job.lastName;

    return encodedLargeTitle;
  };

  // - - - - - - - - - -

  const dataFormatting_encodedSmallTitleNew = (job, jobTitleConfig) => {
    const encodedSmallTitle = jobTitleConfig.reduce((title, conf) => {
      if (job[conf.field] && conf.selected) {
        title += title ? ` • ${job[conf.field]}` : job[conf.field];
      }
      return title;
    }, "");

    return encodedSmallTitle;
  };

  // - - - - - - - - - -

  const dataFormatting_encodedLargeTitleNew = (job, jobTitleConfig) => {
    const encodedLargeTitle = jobTitleConfig.reduce((title, conf) => {
      if (job[conf.field] && conf.selected) {
        title += title ? `\n${job[conf.field]}` : job[conf.field];
      }
      return title;
    }, "");

    return encodedLargeTitle;
  };

  // - - - - - - - - - -

  const createUuid = () => {
    // https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  // - - - - - - - - - -

  const createWeekDatesArray = (startDate) => {
    // user is changing startDate - update the state
    const newArray = [
      startDate.toLocaleDateString("en-us"),
      new Date(
        Date.parse(startDate) + MILLISECONDS_PER_DAY * 1
      ).toLocaleDateString("en-us"),
      new Date(
        Date.parse(startDate) + MILLISECONDS_PER_DAY * 2
      ).toLocaleDateString("en-us"),
      new Date(
        Date.parse(startDate) + MILLISECONDS_PER_DAY * 3
      ).toLocaleDateString("en-us"),
      new Date(
        Date.parse(startDate) + MILLISECONDS_PER_DAY * 4
      ).toLocaleDateString("en-us"),
      new Date(
        Date.parse(startDate) + MILLISECONDS_PER_DAY * 5
      ).toLocaleDateString("en-us"),
      new Date(
        Date.parse(startDate) + MILLISECONDS_PER_DAY * 6
      ).toLocaleDateString("en-us"),
    ];
    setWeekDatesArray(newArray);
    console.log("AppContext createWeekDatesArray: ", newArray);
  };

  // - - - - - - - - - -

  const onSurfaceFiltersChanged = (position) => {
    // user is changing filters - update the state
    console.log("AppContext onSurfaceFiltersChanged[", position, "]...");
    const updatedCheckedState = currentFilters.surfaceFilters.map(
      (item, index) => (index === position ? !item : item)
    );
    var newFilters = { ...currentFilters };
    newFilters.surfaceFilters = updatedCheckedState;
    console.log("AppContext onSurfaceFiltersChanged newFilters: ", newFilters);
    setCurrentFilters(newFilters);
  };

  // - - - - - - - - - -

  const onClassificationFiltersChanged = (position) => {
    // user is changing filters - update the state
    console.log("AppContext onClassificationFiltersChanged[", position, "]...");
    const updatedCheckedState = currentFilters.classificationFilters.map(
      (item, index) => (index === position ? !item : item)
    );
    var newFilters = { ...currentFilters };
    newFilters.classificationFilters = updatedCheckedState;
    setCurrentFilters(newFilters);
  };

  // - - - - - - - - - -

  const onCrewFiltersChanged = (position) => {
    // user is changing filters - update the state
    console.log("AppContext onCrewFiltersChanged[", position, "]...");
    const updatedCheckedState = currentFilters.crewFilters.map((item, index) =>
      index === position ? !item : item
    );
    var newFilters = { ...currentFilters };
    newFilters.crewFilters = updatedCheckedState;
    setCurrentFilters(newFilters);
  };

  // - - - - - - - - - -

  const onCrewSurfaceFiltersChanged = (position) => {
    // user is changing filters - update the state
    console.log("AppContext onCrewSurfaceFiltersChanged[", position, "]...");
    const updatedCheckedState = currentFilters.crewSurfaceFilters.map(
      (item, index) => (index === position ? !item : item)
    );
    var newFilters = { ...currentFilters };
    newFilters.crewSurfaceFilters = updatedCheckedState;
    setCurrentFilters(newFilters);
  };

  // - - - - - - - - - - - - - - - - -  EFFECT HOOKS  - - - - - - - - - - - - - - - - - -
  //
  // AppContext launch                -> createWeekDatesArray(mondayThisWeek)
  //

  useEffect(() => {
    console.log("AppContext useEffect[]...(createWeekDatesArray)");
    // initial app launch
    if (weekDatesArray.length === 0) {
      createWeekDatesArray(mondayThisWeek);
    }
  }, []);

  // - - - - - - - - - - - - - - - - -  CONTEXT RETURN  - - - - - - - - - - - - - - - - - -

  return (
    <AppContext.Provider
      value={{
        companyCode,
        locationFilter,
        jobTitleConfig,
        screenAccess,
        currentFilters,
        locationArray,
        projectDetail,
        candidateDetail,
        mondayThisWeek,
        weekDatesArray,
        latestChangeTS,
        countsToggle,
        lockAccess_unassigned,
        lockAccess_assigned,
        whichList,
        openJobsDetailModal,
        openCrewDetailModal,
        largeView,
        darkMode,
        openJobSettingsModal,
        openSuppressModal,
        appEnvirons,
        defaultNewUserRecord,
        setCompanyCode,
        setLocationFilter,
        setJobTitleConfig,
        setScreenAccess,
        setLocationArray,
        setCurrentFilters,
        setWeekDatesArray,
        setMondayThisWeek,
        setProjectDetail,
        setCandidateDetail,
        setLatestChangeTS,
        setCountsToggle,
        setLockAccess_unassigned,
        setLockAccess_assigned,
        createUuid,
        dataFormatting_dateFields,
        dataFormatting_payrollFields,
        dataFormatting_encodedSmallTitle,
        dataFormatting_encodedLargeTitle,
        dataFormatting_encodedSmallTitleNew,
        dataFormatting_encodedLargeTitleNew,
        createWeekDatesArray,
        onSurfaceFiltersChanged,
        onClassificationFiltersChanged,
        onCrewFiltersChanged,
        onCrewSurfaceFiltersChanged,
        setWhichList,
        setOpenJobsDetailModal,
        setOpenCrewDetailModal,
        setLargeView,
        setDarkMode,
        setOpenJobSettingsModal,
        setOpenSuppressModal,
        setAppEnvirons,
        setDefaultNewUserRecord,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
