import React, { useEffect, useState, useContext, forwardRef } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import { ModelContext } from "../../context/model.context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from "../../context/app.context";

function JobsDetailModal({ jobsDetail, setOpenJobsDetailModal }) {
  const [currPage, setCurrPage] = useState(1);
  const [currentJob, setCurrentJob] = useState(null);
  const [currentExpedite, setCurrentExpedite] = useState(false);
  const [currentDistant, setCurrentDistant] = useState(false);
  const [pagination, setPagination] = useState([]);
  const { createJobAttribute, setAttributeById, setScheduleById } =
    useContext(ModelContext);
  const { setOpenSuppressModal } = useContext(AppContext);
  const [estComplete, setEstComplete] = useState();
  const [arrivalTime, setArrivalTime] = useState();

  // - - - - - - - - - -

  const getInvoiceHref = (invoiceNo) => {
    return `fmp://$/KellerInteriors.fmp12?script=PresentInvoice_(_invoiceNo_)&param=${invoiceNo}`;
  };

  // - - - - - - - - - -

  const getAddressHref = (address) => {
    return "http://maps.apple.com/?address=" + address.replace(/ /gi, "%20");
  };

  // ----------

  const onChangeDetail = (field) => {
    var newValue = false;

    switch (field) {
      case "expedite":
        newValue = !currentJob.jobAttributes.expedite;
        currentJob.jobAttributes.expedite = newValue;
        setCurrentExpedite(newValue);
        console.log("JobsDetailModal onChangeDetail EXPEDITE ...");
        break;

      case "longDistance":
        newValue = !currentJob.jobAttributes.distant;
        currentJob.jobAttributes.distant = newValue;
        setCurrentDistant(newValue);
        console.log("JobsDetailModal onChangeDetail DISTANT ...");
        break;

      default:
        console.log(
          "JobsDetailModal onChangeDetail INVALID SWITCH for field:",
          field
        );
        break;
    }

    // update MongoDB with attributes
    const currentMongoId = currentJob.jobAttributes._id?.toString();
    setAttributeById(currentMongoId, currentJob.jobAttributes);
  };

  // - - - - - - - - - -

  const onEstCompleteChange = (date) => {
    setEstComplete(date);
    const fmDate = new Date(Date.parse(date)).toLocaleDateString("en-us");
    console.log(
      "JobsDetailModal onEstCompleteChange, date: ",
      date,
      ", fmDate: ",
      fmDate,
      ", currentJob: ",
      currentJob
    );
    // 08-30-24 it seems that randomly the mongoId is undefined here
    if (currentJob.mongoId) {
      setScheduleById(
        currentJob.mongoId.toString(),
        currentJob.installer,
        currentJob.start,
        fmDate.toString(),
        currentJob.arrival,
        currentJob.statusCode
      );
    } else {
      console.log(
        "JobsDetailModal onEstCompleteChange error (alert to user 'Unable to save date changes. Please refresh and try again.'): "
      );
      alert("Unable to save date changes. Please refresh and try again.");
    }
  };

  // - - - - - - - - - -

  const onArrivalChange = (arrival) => {
    setArrivalTime(arrival);
    console.log(
      "JobsDetailModal onArrivalChange, arrival: ",
      arrival,
      ", currentJob: ",
      currentJob
    );
    // 08-30-24 it seems that randomly the mongoId is undefined here
    if (currentJob.mongoId) {
      setScheduleById(
        currentJob.mongoId.toString(),
        currentJob.installer,
        currentJob.start,
        currentJob.end,
        arrival,
        currentJob.statusCode
      );
    } else {
      console.log(
        "JobsDetailModal onArrivalChange error (alert to user 'Unable to save time changes. Please refresh and try again.'): "
      );
      alert("Unable to save time changes. Please refresh and try again.");
    }
  };

  // - - - - - - - - - -

  const onSuppressClick = () => {
    // reminder: this button is only visible when statusCode === 3
    const { invoiceNo, classification } = currentJob;
    setOpenSuppressModal({
      currentJob: currentJob,
      show: true,
      suppress: { invoiceNo, classification },
    });
  };

  // - - - - - - - - - -

  useEffect(() => {
    const incomingJob = jobsDetail[currPage - 1];
    setCurrentJob(incomingJob);
    setEstComplete(incomingJob?.end);
    setArrivalTime(incomingJob?.arrival);

    // job specific Attributes need to be added here if not already existing
    var expedite = false;
    var distant = false;
    if (incomingJob.jobAttributes) {
      expedite = incomingJob.jobAttributes.expedite;
      distant = incomingJob.jobAttributes.distant;
    } else {
      const newAttribute = createJobAttribute(incomingJob);
      incomingJob.jobAttributes = newAttribute;
    }
    setCurrentExpedite(expedite);
    setCurrentDistant(distant);

    // console.log(
    //   "JobsDetailModal useEffect[currPage, jobsDetail] incomingJob: ",
    //   incomingJob
    // );
  }, [currPage, jobsDetail]);

  // - - - - - - - - - -

  useEffect(() => {
    let newPagination = [];
    const totalCount = jobsDetail.length;
    if (totalCount < 11) {
      newPagination = jobsDetail.map((_, index) => {
        return index + 1;
      });
    } else {
      // Always print first page button
      newPagination.push(1);

      // Print "..." only if currentPage is > 6
      if (currPage > 6) {
        newPagination.push("...");
      }

      // Print previous number button
      if (currPage > 6 && currPage < totalCount - 4) {
        Array(3)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage - 3 + index);
          });
      }

      if (currPage > 2 && currPage < 7) {
        Array(currPage - 2)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(2 + index);
          });
      }

      if (currPage > 6 && currPage > totalCount - 5) {
        Array(currPage - (totalCount - 7))
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(totalCount - 7 + index);
          });
      }

      //Print current page number button as long as it not the first or last page
      if (currPage !== 1 && currPage !== totalCount) {
        newPagination.push(currPage);
      }

      //print next number button
      if (currPage < totalCount - 4 && currPage > 5) {
        Array(2)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }
      if (currPage < totalCount - 1 && currPage < 6 && currPage !== 1) {
        Array(8 - currPage)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }

      if (currPage < totalCount - 1 && currPage > totalCount - 5) {
        Array(totalCount - currPage - 1)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }

      // special case where first page is selected...
      if (currPage === 1) {
        Array(7)
          .fill(null)
          .forEach((_, index) => {
            newPagination.push(currPage + 1 + index);
          });
      }

      // print "..." if currentPage is < lastPage - 4
      if (currPage < totalCount - 4) {
        newPagination.push("...");
      }

      //Always print last page button if there is more than 1 page
      newPagination.push(totalCount);
    }
    setPagination(newPagination);
  }, [currPage, jobsDetail]);

  // - - - - - - - - - -

  const EstCompleteInput = forwardRef(({ value, onClick, className }, ref) => (
    <button
      onClick={onClick}
      ref={ref}
      className="flex items-center hover:opacity-75 cursor-pointer"
    >
      <span className={className}>{value}</span>
      <span className="ml-1 arrow arrow-down arrow-small"></span>
    </button>
  ));

  // - - - - - - - - - -

  return (
    <Modal onClose={() => setOpenJobsDetailModal({ show: false })}>
      <Modal.Header>
        <div className="flex justify-between">
          <div>Job Details</div>
          {jobsDetail.length > 1 ? (
            <div>
              {currPage} of {jobsDetail.length}
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Header>
      <Modal.Content>
        {currentJob ? (
          <div className="flex flex-col gap-4 w-[400px]">
            <div className="text-lg font-medium">
              Job# <span className="font-bold">{currentJob.projectNo}</span>
            </div>
            <div className="flex flex-wrap">
              <div className="flex-1 basis-2/4">Customer Name</div>
              <div className="flex-1 basis-2/4 font-bold">
                {currentJob.firstName} {currentJob.lastName}
              </div>
              <div className="flex-1 basis-2/4">Customer Contact</div>
              <div className="flex-1 basis-2/4">
                <a
                  className="flex-1 basis-2/4 font-bold text-skin-link hover:opacity-75"
                  href={getAddressHref(currentJob.address)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {currentJob.address}
                </a>
                <div className="font-bold"> {currentJob.cellPhone}</div>
              </div>
            </div>
            <div className="text-lg font-bold">Billing Information</div>
            <div className="flex flex-wrap">
              <div className="flex-1 basis-2/4">Invoice No</div>
              <a
                className="flex-1 basis-2/4 font-bold text-skin-link hover:opacity-75"
                href={getInvoiceHref(currentJob.invoiceNo)}
                target="_blank"
                rel="noreferrer"
              >
                {currentJob.invoiceNo}
              </a>
              <div className="flex-1 basis-2/4">Project No</div>
              <div className="flex-1 basis-2/4 font-bold">
                {currentJob.projectNo}
              </div>
              <div className="flex-1 basis-2/4">Store No</div>
              <a
                className="flex-1 basis-2/4 font-bold text-skin-link hover:opacity-75"
                href="/"
                target="_blank"
                rel="noreferrer"
              >
                {currentJob.storeNo}
              </a>
              <div className="flex-1 basis-2/4">Classification</div>
              <div className="flex-1 basis-2/4 font-bold">
                {currentJob.classification}
              </div>
            </div>
            <div className="text-lg font-bold">Dates</div>
            <div className="flex flex-wrap">
              <div className="flex-1 basis-2/4">Left Message</div>
              <div className="flex-1 basis-2/4 font-bold"></div>
              <div className="flex-1 basis-2/4">Sold Date</div>
              <div className="flex-1 basis-2/4 font-bold"></div>
              <div className="flex-1 basis-2/4">Scheduled</div>
              <div className="flex-1 basis-2/4 font-bold">
                {currentJob.start}
              </div>
              <div className="flex-1 basis-2/4">Est Complete</div>
              <div className="flex-1 basis-2/4 font-bold">
                <DatePicker
                  selected={estComplete}
                  onChange={(date) => onEstCompleteChange(date)}
                  customInput={
                    <EstCompleteInput className="flex-1 basis-2/4 font-bold text-skin-link" />
                  }
                  dateFormat="M/d/YYYY"
                />
              </div>
              <div className="flex-1 basis-2/4">Arrival</div>
              <div className="flex-1 basis-2/4 text-black">
                <select
                  className="bg-yellow-30 text-black text-sm font-medium hover:opacity-75 cursor-pointer"
                  value={arrivalTime}
                  onChange={(e) => onArrivalChange(e.target.value)}
                >
                  <option value="8 AM - 11 AM">8 AM - 11 AM</option>
                  <option value="11 AM - 2 PM">11 AM - 2 PM</option>
                  <option value="2 PM - 5 PM">2 PM - 5 PM</option>
                  <option value={arrivalTime}>{arrivalTime}</option>
                </select>
              </div>
              <div className="flex-1 basis-2/4">Est Received</div>
              <div className="flex-1 basis-2/4 font-bold"></div>
            </div>
            <div></div>
            <div className="flex flex-col gap-2">
              {/* <Checkbox label={"Prescheduled"} disabled={true} value={false} /> */}
              <Checkbox
                label={"Expedite"}
                onChange={(e) => onChangeDetail("expedite")}
                value={currentExpedite}
              />
              <Checkbox
                label={"Long Distance"}
                onChange={(e) => onChangeDetail("longDistance")}
                value={currentDistant}
              />
            </div>
          </div>
        ) : (
          "Job not found"
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className="flex flex-col items-center w-full gap-4">
          {jobsDetail.length > 1 ? (
            <div className="flex justify-center w-full gap-2 px-4 py-2 border-t border-t-yellow-30 font-medium">
              <button
                onClick={() => setCurrPage(currPage - 1)}
                disabled={currPage === 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                >
                  <path d="M15.9547 5.46394L9.41396 12.0047L15.9547 18.5454C16.3452 18.9359 16.3452 19.5691 15.9547 19.9596C15.5641 20.3501 14.931 20.3501 14.5405 19.9596L7.29266 12.7118C7.10506 12.5242 6.99976 12.2699 6.99976 12.0047C6.99976 11.7395 7.10506 11.4851 7.29266 11.2976L14.5405 4.04973C14.5893 4.00091 14.6419 3.9582 14.6973 3.92159C15.0855 3.66531 15.613 3.70802 15.9547 4.04973C16.3452 4.44025 16.3452 5.07342 15.9547 5.46394Z" />
                </svg>
              </button>
              {pagination.map((page, index) => (
                <button
                  onClick={() => setCurrPage(page)}
                  className={`${
                    page === currPage ? "text-accent-pressed" : ""
                  } min-w-4`}
                  key={index}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => setCurrPage(currPage + 1)}
                disabled={currPage === jobsDetail.length}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                >
                  <path d="M9.04533 18.5361L15.586 11.9953L9.04533 5.4546C8.65481 5.0641 8.65481 4.4309 9.04533 4.0404C9.43586 3.6499 10.069 3.6499 10.4595 4.0404L17.7073 11.2882C17.8949 11.4758 18.0002 11.7301 18.0002 11.9953C18.0002 12.2605 17.8949 12.5149 17.7073 12.7024L10.4595 19.9503C10.4107 19.9991 10.3581 20.0418 10.3027 20.0784C9.91452 20.3347 9.38704 20.292 9.04533 19.9503C8.65481 19.5598 8.65481 18.9266 9.04533 18.5361Z" />
                </svg>
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="h-8 flex justify-between w-full">
            <Button
              color="light"
              className="uppercase w-31"
              onClick={() => setOpenJobsDetailModal({ show: false })}
            >
              Close
            </Button>
            {currentJob?.statusCode === 3 ? (
              <Button
                color="light"
                className="uppercase flex gap-2 items-center"
                onClick={() => onSuppressClick()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="fill-skin-primary"
                >
                  <g clipPath="url(#clip0_907_957)">
                    <path d="M7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM11.3333 8.66671H4.66659V7.33337H11.3333V8.66671Z" />
                  </g>
                  <defs>
                    <clipPath id="clip0_907_957">
                      <rect width="16" height="16" />
                    </clipPath>
                  </defs>
                </svg>
                Suppress
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default JobsDetailModal;
