import React from "react";

const JobTile = ({
  project,
  projectDetailClick,
  dragStarted,
  className = "",
  largeView,
  draggable = true,
}) => {
  const firstSpaceIndex = project?.encodedSmallTitle?.indexOf(" ");

  // add tooltips to emoji icons
  var tooltipText = "";
  tooltipText = project?.encodedSmallTitle?.includes("📦")
    ? `📦 expressShip\n`
    : tooltipText;
  tooltipText = project?.encodedSmallTitle?.includes("🪑")
    ? tooltipText + `🪑 furniture\n`
    : tooltipText;
  tooltipText = project?.encodedSmallTitle?.includes("📅")
    ? tooltipText + `📅 prescheduled\n`
    : tooltipText;
  tooltipText = project?.encodedSmallTitle?.includes("⚡️")
    ? tooltipText + `⚡️ rush\n`
    : tooltipText;
  tooltipText = project?.encodedSmallTitle?.includes("🏃🏽‍♂️")
    ? tooltipText + `🏃🏽‍♂️ expedite\n`
    : tooltipText;
  tooltipText = project?.encodedSmallTitle?.includes("✈️")
    ? tooltipText + `✈️ distant\n`
    : tooltipText;

  return (
    <>
      {project?.attribute ? (
        <div
          key={project.id}
          className={`text-sm-2 min-h-6 px-2 flex bg-attribute-data rounded text-black ${className}`}
        >
          <span className="self-center font-medium">
            {largeView
              ? project?.encodedLargeTitle
              : project?.encodedSmallTitle}
          </span>
        </div>
      ) : (
        <div
          key={project?.id}
          draggable={draggable}
          onClick={(event) => projectDetailClick(project?.id, event)}
          onDragStart={() => dragStarted(project?.id)}
          className={`text-sm-2 min-h-6 flex cursor-pointer hover:bg-skin-base-hover ${className}`}
        >
          <div
            className={`bg-surface-${project?.surface
              ?.split(" ")
              .join("-")} badge`}
          ></div>
          {largeView ? (
            <span className="mx-2 self-center font-medium whitespace-pre-line  first-line:font-bold">
              {project?.encodedLargeTitle}
            </span>
          ) : tooltipText !== "" ? (
            <span className="mx-2 self-center font-medium">
              <span className="font-bold tooltip">
                {project?.encodedSmallTitle?.substring(0, firstSpaceIndex)}
                <span className="tooltiptext">{tooltipText}</span>
              </span>
              {project?.encodedSmallTitle?.substring(firstSpaceIndex)}
            </span>
          ) : (
            <span className="mx-2 self-center font-medium">
              <span className="font-bold">
                {project?.encodedSmallTitle?.substring(0, firstSpaceIndex)}
              </span>
              {project?.encodedSmallTitle?.substring(firstSpaceIndex)}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default JobTile;
